import { Component, OnDestroy } from '@angular/core';
import { SubscriptionLike } from 'rxjs';
import { StoreSettingsService } from '@services/setting-services/store-settings.service';

@Component({
  selector: 'app-sse',
  standalone: true,
  imports: [],
  templateUrl: './sse.component.html',
  styleUrl: './sse.component.scss',
})
export class SSEComponent implements OnDestroy {
  private readonly eventSourceSubscription: SubscriptionLike;

  constructor(private settingsService: StoreSettingsService) {
    this.eventSourceSubscription = this.settingsService.connectToServerSentEvents().subscribe({
      next: data => {
        console.log('SEE => ', data);
        //this.settingsService.updateStoreConfig(data);
      },
      error: error => {
        //handle error
      },
    });
  }

  ngOnDestroy() {
    this.eventSourceSubscription.unsubscribe();
    this.settingsService.disconnectServerSentEvents();
  }
}
