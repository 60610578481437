import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  importProvidersFrom,
  ApplicationConfig,
} from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpErrorHandler } from '@services/form-services/error_handle.service';
import { provideToastr } from 'ngx-toastr';
import { AuthInterceptor } from '@services/auth-services/auth-interceptor.service';
import { provideRouter, Router, withViewTransitions } from '@angular/router';
import { routes } from 'src/app/dashboard-module/dashboard-module-routing.module';
import { provideClientHydration } from '@angular/platform-browser';
import { provideZoneChangeDetection } from '@angular/core';
import { withFetch } from '@angular/common/http';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxParticlesModule } from '@tsparticles/angular';

export function initializeSentry() {
  return () => {
    if (environment.environment !== 'local') {
      Sentry.init({
        environment: environment.environment,
        dsn: 'https://e8ce7b02895ddc0edb506c8d53115895@o1100562.ingest.us.sentry.io/4507554457059328',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
          Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: 'system',
            isNameRequired: true,
            showBranding: false,
            enableScreenshot: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['*.localhost', /^https:\/\/*.commercengine\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }
  };
}
export function playerFactory() {
  return player;
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideLottieOptions({
      player: () => player,
    }),
    importProvidersFrom(
      AppRoutingModule,
      NgbModule,
      NgbNavModule,
      FormsModule,
      NgxParticlesModule,
      NgxDaterangepickerMd.forRoot(),
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth())
    ),
    HttpErrorHandler,
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideRouter(routes, withViewTransitions()),
    provideAnimations(),
    provideToastr({
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSentry,
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideClientHydration(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withViewTransitions() //Enable view transitions API
    ),
  ],
};
